import * as React from "react";

interface ContainerProps {
  className?: string;
  children: any;
}

const Container: React.FC<ContainerProps> = ({ children }) => <div className={"relative mx-auto w-auto"}>{children}</div>;

export default Container;
